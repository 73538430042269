import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import ShareIcon from "@mui/icons-material/Share";
import {
  Box,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BaseScreen } from "~/common/components/base-screen/base-screen";
import { Product } from "~/common/entities/product";
import { ProductCard } from "~/features/shop/components/product-card/product-card";
import {
  getProductById,
  getRelatedProducts,
} from "~/features/shop/services/get-products";

import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { useDispatch } from "react-redux";
import InstallmentsCalculator from "~/common/components/installments-calculator/installments-calculator";
import { addToCart } from "~/redux/slicers/cartSlicer";
import { cleanUpText } from "~/utils/clean-up-text";
import { formatAndSanitizeHTML } from "~/utils/format-and-sanitize-html";
import {
  ActionsContainer,
  DescriptionContainer,
  IconButtonsContainer,
  MainContainer,
  PrimaryActionButton,
  ProductDetailsWrapper,
  ProductImage,
  ProductInfoContainer,
  RelatedProductsContainer,
  SectionTitle,
} from "./product-page.styles";
import ProductSchema from "~/schema/product-schema";
import ProductMetaTags from "~/meta/product-meta-tags";

const ProductPage: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = React.useState<Product | null>(null);
  const [relatedProducts, setRelatedProducts] = React.useState<Product[]>([]);
  const dispatch = useDispatch();

  React.useEffect(() => {
    const fetchProduct = async () => {
      try {
        if (!params.id || isNaN(Number(params.id))) {
          navigate("/");
          return;
        }

        const result = await getProductById(params.id);
        setProduct(result.response);
      } catch (error) {
        console.error("Erro ao carregar produto:", error);
      }
    };

    fetchProduct();
  }, [navigate, params.id]);

  React.useEffect(() => {
    const fetchRelated = async () => {
      if (product?.id) {
        try {
          const result = await getRelatedProducts(product.id.toString());
          setRelatedProducts(result.response);
        } catch (error) {
          console.error("Erro ao carregar produtos relacionados:", error);
        }
      }
    };

    fetchRelated();
  }, [product]);

  const handleBuyNow = () => {
    dispatch(addToCart(product as Product));
    navigate("/carrinho");
  };

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: product?.title,
          text: `Confira este produto: ${product?.title}`,
          url: window.location.href,
        });
      } catch (error) {
        console.error("Erro ao compartilhar:", error);
      }
    } else {
      navigator.clipboard.writeText(window.location.href);
      alert("Link do produto copiado para a área de transferência!");
    }
  };

  const renderMainContent = () => {
    if (!product) {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="50vh"
        >
          <CircularProgress />
        </Box>
      );
    }

    const hasDiscount = (product.discount ?? 0) > 0;
    const hasDocuments =
      product.totalDocumentsCount !== undefined &&
      product.totalDocumentsCount > 0;
    const hasLessons =
      product.totalLessonsCount !== undefined && product.totalLessonsCount > 0;

    return (
      <MainContainer>
        <ProductSchema product={product} />
        <ProductMetaTags product={product} />
        <ProductDetailsWrapper>
          <Box
            sx={{
              flex: "1 1 50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ProductImage
              src={product.imageUrl || "/placeholder.jpg"}
              alt={product.title}
            />
          </Box>

          <Box
            sx={{
              flex: "1 1 50%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <ProductInfoContainer>
              <Typography variant="h4" fontWeight="bold">
                {product.title}
              </Typography>

              {product.resumedDescription && (
                <Typography variant="body1" color="text.secondary">
                  {cleanUpText(product.resumedDescription ?? "")}
                </Typography>
              )}

              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2, mt: 2 }}>
                {hasDiscount && (
                  <Chip
                    label={`${product.discount}% OFF`}
                    color="secondary"
                    style={{
                      fontWeight: 600,
                    }}
                  />
                )}
                {product?.category?.name && (
                  <Chip
                    label={product?.category.name}
                    variant="outlined"
                    size="medium"
                    sx={{
                      fontWeight: 500,
                      borderColor: "primary.main",
                      color: "primary.main",
                    }}
                  />
                )}
                {hasDocuments && (
                  <Chip
                    label={`${product?.totalDocumentsCount} Documento${(product?.totalDocumentsCount ?? 0) > 1 ? "s" : ""}`}
                    variant="outlined"
                    size="medium"
                    sx={{
                      fontWeight: 500,
                      borderColor: "secondary.main",
                      color: "secondary.main",
                    }}
                  />
                )}
                {hasLessons && (
                  <Chip
                    label={`${product?.totalLessonsCount} Aula${(product?.totalLessonsCount ?? 0) > 1 ? "s" : ""}`}
                    variant="outlined"
                    size="medium"
                    sx={{
                      fontWeight: 500,
                      borderColor: "primary.main",
                      color: "primary.main",
                    }}
                  />
                )}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1.5,
                  my: 2,
                }}
              >
                {hasDocuments && (
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <PictureAsPdfIcon color="secondary" fontSize="small" />
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      fontWeight="500"
                    >
                      Material didático em PDF
                    </Typography>
                  </Box>
                )}
                {hasLessons && (
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <OndemandVideoIcon color="primary" fontSize="small" />
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      fontWeight="500"
                    >
                      Curso em vídeo com {product.totalLessonsCount} aula
                      {(product.totalLessonsCount ?? 0) > 1 ? "s" : ""}
                    </Typography>
                  </Box>
                )}
              </Box>

              <Box sx={{ mb: 2 }}>
                <InstallmentsCalculator
                  price={product.price ?? 0}
                  discountedPrice={product.discountedPrice}
                  minInstallmentValue={5}
                  maxInstallments={12}
                />
              </Box>

              <ActionsContainer sx={{ mb: 2 }}>
                <PrimaryActionButton
                  variant="contained"
                  color="primary"
                  onClick={handleBuyNow}
                >
                  Comprar agora
                </PrimaryActionButton>
              </ActionsContainer>

              <IconButtonsContainer
                sx={{
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: 1,
                  mt: 1,
                  cursor: "pointer",
                  color: "primary.main",
                }}
                onClick={handleShare}
              >
                <ShareIcon />
                <Typography variant="body2">Compartilhar produto</Typography>
              </IconButtonsContainer>
            </ProductInfoContainer>
          </Box>
        </ProductDetailsWrapper>

        <DescriptionContainer>
          <Divider sx={{ my: 3 }} />
          <SectionTitle variant="h5" gutterBottom>
            Descrição do Produto
          </SectionTitle>
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{ whiteSpace: "pre-wrap" }}
            dangerouslySetInnerHTML={formatAndSanitizeHTML(
              product.description ?? "",
            )}
          />
          <Divider sx={{ my: 3 }} />
        </DescriptionContainer>

        <RelatedProductsContainer>
          <SectionTitle variant="h5" gutterBottom>
            Você também pode gostar
          </SectionTitle>
          <Grid container spacing={3}>
            {relatedProducts.length === 0 ? (
              <Typography variant="body2" color="text.secondary" mt={2} ml={3}>
                Nenhum produto relacionado encontrado.
              </Typography>
            ) : (
              relatedProducts.map((related) => (
                <Grid item xs={12} sm={6} md={3} key={related.id}>
                  <ProductCard product={related} />
                </Grid>
              ))
            )}
          </Grid>
        </RelatedProductsContainer>
      </MainContainer>
    );
  };

  return <BaseScreen>{renderMainContent()}</BaseScreen>;
};

export default ProductPage;
